h1 {
    color: red;
}

.Button {
    color: black;
}

.Button:disabled {
    color: white;
}

.Button:enabled {
    color: black;
}

.MuiStackRoot {
    padding-top: 0px;
}

.MuiList-root {
    padding-top: 0px;
}